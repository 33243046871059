export const analyticsEvent = {
  // Авторизация / Authentication
  // Авторизовался автоматически
  auth_signin_auto: (payload: { user_id: number }) => ({
    event: 'auth_signin_auto',
    ...payload,
  }),
  // Вышел из профиля через кнопку выйти
  auth_signout_via_signout_button: () => ({
    event: 'auth_signout_via_signout_button',
  }),
  // Настройка профиля / Profile Setup
  // Подключил бота 1, 2, 3
  bot_connected_1: () => ({
    event: 'bot_connected_1',
  }),
  bot_connected_2: () => ({
    event: 'bot_connected_2',
  }),
  bot_connected_3: () => ({
    event: 'bot_connected_3',
  }),
  // Перешел в раздел «мой профиль»
  profile_section_opened: () => ({
    event: 'profile_section_opened',
  }),
  // Подключил канал
  channel_connected: () => ({
    event: 'channel_connected',
  }),
  // Перешел в канал
  channel_opened: () => ({
    event: 'channel_opened',
  }),
  // Перешел в «управление администраторами»
  admin_management_opened: () => ({
    event: 'admin_management_opened',
  }),
  // Выбрал канал в «управление администраторами»
  admin_channel_selected: (payload: { channel_name: string }) => ({
    event: 'admin_channel_selected',
    ...payload,
  }),
  // Добавил администратора в канал
  admin_added_to_channel: (payload: { channel_name: string }) => ({
    event: 'admin_added_to_channel',
    ...payload,
  }),
  // Кликнул по кнопке «оставить заявку в смит линк»
  submit_request_click_smit_link: () => ({
    event: 'submit_request_click_smit_link',
  }),
  // Отсортировал каналы
  channels_sorted: () => ({
    event: 'channels_sorted',
  }),
  // Добавил канал в избранное
  channel_added_to_favorites: (payload: { channel_name: string }) => ({
    event: 'channel_added_to_favorites',
    ...payload,
  }),
  // Пригласительные ссылки / Invitation Links
  // Создал пригласительную ссылку
  invite_link_created: () => ({
    event: 'invite_link_created',
  }),
  // Добавил участника
  participant_added: () => ({
    event: 'participant_added',
  }),
  // Выбрал поиск по роли
  role_search_selected: () => ({
    event: 'role_search_selected',
  }),
  // Модуль База / Base Module
  // Перешел на базовые метрики
  base_metrics_view_opened: () => ({
    event: 'base_metrics_view_opened',
  }),
  // Настроил период в базовых метриках
  base_metrics_period_set: () => ({
    event: 'base_metrics_period_set',
  }),
  // Модуль Трафик / Traffic Module
  // Перешел на трафик
  traffic_view_opened: () => ({
    event: 'traffic_view_opened',
  }),
  // Настроил период в трафике
  traffic_period_set: () => ({
    event: 'traffic_period_set',
  }),
  // Выбрал ссылки (фильтрация)
  traffic_links_filtered: () => ({
    event: 'traffic_links_filtered',
  }),
  // Выбрал фильтр в «Список рекламных кампаний»
  traffic_campaigns_filtered: (payload: { value: string }) => {
    return {
      event: 'traffic_campaigns_filtered',
      ...payload,
    }
  },
  // Наличие никнеймов
  traffic_nicknames_filtered: (payload: {
    value:
      | 'subscription'
      | 'unsubscription'
      | 'with_nickname'
      | 'without_nickname'
  }) => {
    return {
      event: 'traffic_nicknames_filtered',
      ...payload,
    }
  },
  // Процент отписок новых подписчиков, когорты отписок
  traffic_unsub_percentage_set: (payload: { value: string }) => ({
    event: 'traffic_unsub_percentage_set',
    ...payload,
  }),
  // Сделал выгрузку из Трафика «аудитория»
  traffic_audience_exported: () => ({
    event: 'traffic_audience_exported',
  }),
  // Модуль Контент / Content Module
  // Перешел на модуль Контент
  content_module_opened: () => ({
    event: 'content_module_opened',
  }),
  // Модуль Продажи / Sales Module
  // Перешел на раздел Продажи
  sales_view_opened: () => ({
    event: 'sales_view_opened',
  }),
  // Создал выгрузку в «Продажи»
  sales_export_created: () => ({
    event: 'sales_export_created',
  }),
  // Выбрал выгрузку
  sales_export_selected: () => ({
    event: 'sales_export_selected',
  }),
  // Внес сумму рекламной кампании
  sales_campaign_budget_set: () => ({
    event: 'sales_campaign_budget_set',
  }),
  // Внес потрачено на рекламную кампанию
  sales_expenses_entered: () => ({
    event: 'sales_expenses_entered',
  }),
  // Переименовал сверку
  sales_verification_renamed: () => ({
    event: 'sales_verification_renamed',
  }),
  // Выбрал ссылки
  sales_links_filtered: () => ({
    event: 'sales_links_filtered',
  }),
  // Informers
  // Перешел по кнопке в поп-апе название
  popup_button_clicked: (payload: {
    popup_name: string
    button_name: string
  }) => ({
    event: 'popup_cta_clicked',
    ...payload,
  }),
  // Закрыл поп-ап название
  popup_closed: (payload: { popup_name: string }) => ({
    event: 'popup_closed',
    ...payload,
  }),
  // Нажал на кнопку «Согласился на куки»
  cookies_consent_accepted: () => ({
    event: 'cookies_consent_accepted',
  }),
}
