'use client'

import { useParams, usePathname, useSearchParams } from 'next/navigation'
import { useEffect, useRef } from 'react'

export type NavigationRouteChangedEventPayload = {
  url: string
  pathname: string
  params: Record<string, string | string[]>
}

export interface NavigationEvents {
  routeChanged: ({
    url,
    pathname,
    params,
  }: NavigationRouteChangedEventPayload) => void
}

const usePrevious = <T>(value: T) => {
  const ref = useRef<T>(value)
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const useNavigation = ({
  on: { routeChanged },
}: {
  on: NavigationEvents
}) => {
  const isFirst = useRef(true)

  const pathname = usePathname()
  const prevPathname = usePrevious(pathname)

  const searchParams = useSearchParams()
  const prevSearchParams = usePrevious(searchParams)

  const params = useParams()

  useEffect(() => {
    let url = window.origin + pathname
    if (searchParams?.toString()) {
      url = url + `?${searchParams.toString()}`
    }
    if (isFirst.current) {
      isFirst.current = false
      routeChanged({ url, pathname, params })
    } else if (
      searchParams?.toString() !== prevSearchParams?.toString() ||
      pathname !== prevPathname
    ) {
      routeChanged({ url, pathname, params })
    }
  }, [
    pathname,
    prevPathname,
    prevSearchParams,
    routeChanged,
    searchParams,
    params,
  ])
}
